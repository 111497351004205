<template>
  <b-container
    fluid
  >
    <div class="d-flex justify-content-end">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-container
      fluid
    >

      <b-row>

        <b-col
          cols="12"
          md="8"
          class="px-1"
        >
          <b-form-group
            label="Search"
            label-for="filterLocationSearch"
            label-class="font-weight-bolder"
            class="mb-2"
          >
            <b-input
              id="filterLocationSearch"
              v-model="tableLocations.filter.search"
              placeholder="search here"
              autocomplete="off"
              debounce="1000"
              type="text"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="4"
          class="px-1"
        >
          <b-form-group
            label="Warehouse"
            label-for="filterWarehouse"
            label-class="font-weight-bolder"
            class="mb-2"
          >
            <v-select
              v-model="tableLocations.selected.warehouse"
              placeholder="search warehouse here"
              label="warehouse_name"
              class="vs-white"
              clearable
              append-to-body
              input-id="filterWarehouse"
              :options="(tableLocations.options.warehouses)"
              :loading="(tableLocations.fetching.warehouses)"
              :disabled="(tableLocations.busy || tableLocations.fetching.warehouses)"
              :calculate-position="calculateDropPosition"
            >
              <template #option="{ warehouse_code, warehouse_name }">
                <div class="d-flex flex-column flex-md-row py-1">
                  <div class="flex-grow-1">
                    {{ warehouse_name }}
                  </div>
                  <strong class="min-w-75px">{{ warehouse_code }}</strong>
                </div>
              </template>
              <template #no-options="">
                no available warehouse
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          class="px-1 mb-2"
        >
          <b-button
            size="sm"
            type="button"
            variant="success"
            @click.prevent="createLocation"
          >
            Add Location
          </b-button>
        </b-col>

      </b-row>

      <b-row
        class="mt-1"
      >
        <b-col
          cols="12"
          class="px-1"
        >
          <b-table
            ref="tableLocations"
            hover
            small
            striped
            bordered
            responsive
            show-empty
            :items="tableProvider"
            :busy="tableLocations.busy"
            :filter="tableLocations.filter"
            :fields="tableLocations.fields"
            :stacked="tableLocations.stacked"
            :sort-by.sync="tableLocations.sortBy"
            :sort-desc.sync="tableLocations.sortDesc"
            :sort-direction="tableLocations.sortDirection"
            :filter-included-fields="tableLocations.filterOn"
            :current-page="tableLocations.currentPage"
            :per-page="tableLocations.perPage"
          >
            <template #cell(index)="{ index }">
              {{ tableRowNumber(tableLocations, index) }}
            </template>

            <template #cell()="{ value }">
              <div class="text-md-nowrap">
                {{ value }}
              </div>
            </template>

            <template #cell(action)="{ item }">
              <div class="text-md-nowrap d-flex flex-column flex-md-row justify-content-center">
                <b-button
                  size="sm"
                  variant="success"
                  @click.prevent="editLocation(item)"
                >
                  Edit
                </b-button>
              </div>
            </template>

            <template #table-busy>
              <div class="text-center py-5">
                <b-icon
                  icon="stopwatch"
                  font-scale="5"
                  animation="cylon"
                />
                <p class="h3 py-2">
                  <strong>Loading . . .</strong>
                </p>
              </div>
            </template>

          </b-table>
        </b-col>

        <b-col
          cols="12"
          md="6"
          class="mt-1 mb-2 px-1"
        >
          <b-select
            v-model="tableLocations.perPage"
            :options="tableLocations.pageOptions"
            :disabled="tableLocations.busy"
            class="w-100 w-md-25"
            size="sm"
          />
        </b-col>

        <b-col
          cols="12"
          md="6"
          class="d-flex justify-content-center justify-content-md-end mt-1 px-1"
        >
          <b-pagination
            v-model="tableLocations.currentPage"
            :total-rows="tableLocations.totalRows"
            :per-page="tableLocations.perPage"
            :disabled="tableLocations.busy"
            aria-controls="table"
            prev-text="Prev"
            next-text="Next"
            first-number
            last-number
            pills
          />
        </b-col>
      </b-row>
    </b-container>

    <b-modal
      id="modalLocation"
      scrollable
      no-close-on-esc
      no-enforce-focus
      no-close-on-backdrop
      :title="ModalLocationTitle"
    >
      <ValidationObserver
        ref="formLocation"
      >
        <form
          role="form"
          novalidate
          @submit.prevent
        >
          <b-row>

            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="prefix"
                vid="prefix"
                rules="required|max:3"
              >
                <b-form-group
                  label="Prefix"
                  label-for="prefix"
                  label-class="font-weight-bolder"
                  class="mb-2"
                >
                  <b-input
                    id="prefix"
                    v-model="location.prefix"
                    type="text"
                    autocomplete="off"
                    maxlength="3"
                    placeholder="enter prefix here"
                    :state="(errors.length > 0 ? false : null)"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                    v-text="errors[0]"
                  />
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="location code"
                vid="location_code"
                rules="required|max:30"
              >
                <b-form-group
                  label="Location Code"
                  label-for="location_code"
                  label-class="font-weight-bolder"
                  class="mb-2"
                >
                  <b-input
                    id="location_code"
                    v-model="location.location_code"
                    type="text"
                    autocomplete="off"
                    maxlength="30"
                    placeholder="enter location code here"
                    :state="(errors.length > 0 ? false : null)"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                    v-text="errors[0]"
                  />
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="location name"
                vid="location_name"
                rules="required|max:100"
              >
                <b-form-group
                  label="Location Name"
                  label-for="location_name"
                  label-class="font-weight-bolder"
                  class="mb-2"
                >
                  <b-input
                    id="location_name"
                    v-model="location.location_name"
                    type="text"
                    autocomplete="off"
                    maxlength="100"
                    placeholder="enter location name here"
                    :state="(errors.length > 0 ? false : null)"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                    v-text="errors[0]"
                  />
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                vid="warehouse"
                name="warehouse"
              >
                <b-form-group
                  label="Warehouse"
                  label-for="warehouse"
                  label-class="font-weight-bolder"
                  class="mb-2"
                >
                  <v-select
                    v-model="selected.warehouse"
                    placeholder="search warehouse here"
                    label="warehouse_name"
                    clearable
                    append-to-body
                    input-id="warehouse"
                    :options="(options.warehouses)"
                    :loading="(fetching.warehouses)"
                    :disabled="(state.busy || fetching.warehouses)"
                    :calculate-position="calculateDropPosition"
                    :class="{ 'is-invalid': errors.length > 0 }"
                  >
                    <template #option="{ warehouse_code, warehouse_name }">
                      <div class="d-flex flex-column flex-md-row py-1">
                        <div class="flex-grow-1">
                          {{ warehouse_name }}
                        </div>
                        <strong class="min-w-75px">{{ warehouse_code }}</strong>
                      </div>
                    </template>
                    <template #no-options="">
                      no available warehoue
                    </template>
                  </v-select>
                  <div
                    v-if="(errors.length > 0)"
                    class="invalid-feedback"
                    v-text="errors[0]"
                  />
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
            >
              <b-form-group>
                <b-form-checkbox
                  id="allow_in"
                  v-model="location.allow_in"
                  switch
                  value="1"
                  name="allow_in"
                  unchecked-value="0"
                  :disabled="state.busy"
                >
                  Can Stock In?
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
            >
              <b-form-group>
                <b-form-checkbox
                  id="allow_out"
                  v-model="location.allow_out"
                  switch
                  value="1"
                  name="allow_out"
                  unchecked-value="0"
                  :disabled="state.busy"
                >
                  Can Stock Out?
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col
              v-if="state.editing"
              cols="12"
            >
              <b-form-group>
                <b-form-checkbox
                  id="is_active"
                  v-model="location.active"
                  switch
                  value="1"
                  name="is_active"
                  unchecked-value="0"
                  :disabled="state.busy"
                >
                  Is Active?
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>

        </form>
      </ValidationObserver>

      <template #modal-footer="{cancel}">
        <b-button
          variant="success"
          :disabled="state.busy"
          @click.prevent="validateLocation"
        >
          {{ state.editing ? 'Update Record' : 'Save Record' }}
        </b-button>
        <b-button
          variant="outline-dark"
          :disabled="state.busy"
          @click.prevent="cancel()"
        >
          Close Window
        </b-button>
      </template>

    </b-modal>
  </b-container>
</template>

<script>
import QUERY from 'lodash'
import MISC from '@/mixins/misc'
import FORMATTER from '@/mixins/formatter'
import { core } from '@/config/pluginInit'
import { SAdminLocation, SSharedList } from '@/services'

export default {
  name: 'AdminLocations',

  middleware: [
    'auth',
    'admin'
  ],

  mixins: [
    MISC,
    FORMATTER
  ],

  metaInfo: () => ({
    title: 'Locations'
  }),

  data () {
    return {
      state: {
        busy: false,
        editing: false
      },
      fetching: {
        warehouses: false
      },
      options: {
        warehouses: []
      },
      selected: {
        warehouse: null
      },
      location: {
        id: null,
        warehouse: null,
        prefix: null,
        location_code: null,
        location_name: null,
        allow_in: null,
        allow_out: null,
        active: 1
      },
      tableLocations: {
        busy: false,
        fetching: {
          warehouses: false
        },
        options: {
          warehouses: []
        },
        selected: {
          warehouse: null
        },
        filter: {
          search: null,
          warehouse: null
        },
        filterOn: [],
        stacked: this.isMobile(),
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        pageOptions: [10, 25, 50, 100],
        currentPage: 1,
        totalRows: 0,
        perPage: 10,
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 9, key: 'action', class: 'text-center' },
          { mobile: 1, key: 'created_at', formatter: this.dateTimeShortFormatter, sortable: true },
          { mobile: 2, key: 'prefix', class: 'text-center min-w-50px' },
          { mobile: 3, key: 'location_code', class: 'min-w-100px' },
          { mobile: 4, key: 'location_name', class: 'min-w-175px' },
          { mobile: 4, key: 'warehouse.warehouse_name', label: 'Warehouse', class: 'min-w-175px' },
          { mobile: 5, key: 'active', class: 'text-center', formatter: this.yesOrNo },
          { mobile: 6, key: 'allow_in', class: 'text-center', formatter: this.yesOrNo },
          { mobile: 7, key: 'allow_out', class: 'text-center', formatter: this.yesOrNo },
          { mobile: 8, key: 'updated_at', formatter: this.dateTimeShortFormatter, sortable: true }
        ].sort(this.scaleOnMobile)
      }
    }
  },

  computed: {
    ModalLocationTitle () {
      return this.state.editing ? 'Edit Location' : 'Add Location'
    }
  },

  watch: {
    'selected.warehouse' (warehouse) {
      this.location.warehouse = warehouse?.id || null
    },

    'tableLocations.selected.warehouse' (warehouse) {
      this.tableLocations.filter.warehouse = warehouse?.id || null
    }
  },

  mounted () {
    core.index()
    this.getFilterWarehouses()
  },

  methods: {

    async tableProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      this.tableLocations.busy = true
      return await SAdminLocation.get({
        page: currentPage,
        per_page: perPage,
        sort: sortBy,
        sort_desc: sortDesc,
        filter_text: filter.search,
        filter_warehouse: filter.warehouse
      }).then(({ data }) => {
        this.tableLocations.totalRows = data.total_rows
        return data.items
      }).catch(() => {
        this.tableLocations.totalRows = 0
        return []
      }).finally(() => {
        this.tableLocations.busy = false
      })
    },

    async getFilterWarehouses () {
      this.tableLocations.fetching.warehouses = true
      return await SSharedList.getWarehouses().then(({ data }) => {
        this.tableLocations.options.warehouses = data
      }).catch(() => {
        this.tableLocations.options.warehouses = []
      }).finally(() => {
        this.tableLocations.fetching.warehouses = false
      })
    },

    async getWarehouses () {
      this.fetching.warehouses = true
      return await SSharedList.getWarehouses().then(({ data }) => {
        this.options.warehouses = data
      }).catch(() => {
        this.options.warehouses = []
      }).finally(() => {
        this.fetching.warehouses = false
      })
    },

    createLocation () {
      this.state.editing = false
      this.location.id = null
      this.location.prefix = null
      this.location.location_code = null
      this.location.location_name = null
      this.location.allow_in = 0
      this.location.allow_out = 0
      this.location.active = 1

      this.selected.warehouse = null

      this.getWarehouses()

      this.$bvModal.show('modalLocation')
    },

    editLocation (location) {
      this.state.editing = true
      this.location.id = location.id
      this.location.prefix = location.prefix
      this.location.location_code = location.location_code
      this.location.location_name = location.location_name
      this.location.allow_in = location.allow_in
      this.location.allow_out = location.allow_out
      this.location.active = location.active

      this.getWarehouses().finally(() => {
        this.selected.warehouse = QUERY.find(this.options.warehouses, {
          id: Number(location.warehouse_id)
        })
      })

      this.$bvModal.show('modalLocation')
    },

    async validateLocation (event) {
      event.preventDefault()
      await this.$refs.formLocation.validate().then(
        async validLocation => {
          if (!validLocation) {
            return this.swalInvalid()
          }

          this.swalConfirm({
            html: () => {
              if (this.state.editing) {
                return '<h6>Save your changes on this Location?</h6>'
              }
              return '<h6>Create this Location?</h6>'
            },
            preConfirm: () => {
              this.state.busy = true
              if (this.state.editing) {
                return this.updateLocation()
              }
              return this.submitLocation()
            }
          })
        }
      )
    },

    async submitLocation () {
      return await SAdminLocation.post(this.location).then(
        ({ data: { message } }) => {
          this.$bvModal.hide('modalLocation')
          this.swalSuccess(message).then(() => {
            this.$refs.tableLocations.refresh()
          })
        }
      ).catch(({ message }) => {
        this.$refs.formLocation.setErrors(message)
      }).finally(() => {
        this.state.busy = false
      })
    },

    async updateLocation () {
      return await SAdminLocation.put(this.location).then(
        ({ data: { message, location } }) => {
          this.$bvModal.hide('modalLocation')
          this.swalSuccess(message).then(() => {
            const tableLocationsRow = QUERY.find(this.$refs.tableLocations.localItems, {
              id: Number(location.id)
            })
            if (tableLocationsRow) {
              tableLocationsRow.warehouse = location.warehouse
              tableLocationsRow.warehouse_id = location.warehouse_id
              tableLocationsRow.prefix = location.prefix
              tableLocationsRow.location_code = location.location_code
              tableLocationsRow.location_name = location.location_name
              tableLocationsRow.allow_in = location.allow_in
              tableLocationsRow.allow_out = location.allow_out
              tableLocationsRow.active = location.active
              tableLocationsRow.updated_at = location.updated_at
            }
          })
        }
      ).catch(({ message }) => {
        this.$refs.formLocation.setErrors(message)
      }).finally(() => {
        this.state.busy = false
      })
    }
  }
}
</script>
